import loadable from "@loadable/component";
import { inject } from "mobx-react";

import { formatImage } from "@ihr-radioedit/inferno-core";
import type { FeedResultFragment } from "@ihr-radioedit/inferno-webapi";
import { type Store, isPublishRecord, RESPONSIVE_IMAGE, reverseRoute } from "@inferno/renderer-shared-core";
import { MagicLink } from "@inferno/renderer-shared-ui";

import { Heading } from "../../../../core/ui";
import { ResponsiveImage } from "../../../../core/components/ResponsiveImage.component";
import { CoastFeedItemType, getCoastFeedImg } from "../../lib/coastFeed";
import { CoastLinkedGuests } from "../CoastLinkedGuests.component";
import { CoastLinkedHost } from "../CoastLinkedHost.component";
import { CoastShowDate } from "./CoastShowDate.component";

interface CoastFeedTonightShowItemProps {
  item: FeedResultFragment;
  title: string;
  store?: Store;
}

const CoastFeedTonightShowMediaHeaderLoader = loadable(
  () => import("./CoastFeedTonightShowMediaHeaderLoader.component"),
  {
    ssr: false,
  },
);

export const CoastFeedTonightShowItem = inject("store")(({ item, store, title }: CoastFeedTonightShowItemProps) => {
  if (!store || !isPublishRecord(item.record)) {
    return null;
  }

  const { env } = store;
  const { slug, payload } = item.record;
  const { summary } = payload;

  const thumb = formatImage(summary.image.url, env.IMAGE_HOST);
  const { imgSrc, srcset } = getCoastFeedImg(thumb, CoastFeedItemType.SHOW, { quality: 80 });

  const showUrl = reverseRoute(store.site, "shows_detail", { slug }) || "#";

  return (
    <>
      <Heading level={2}>
        {title}
        <CoastFeedTonightShowMediaHeaderLoader />
      </Heading>

      <div className="coast-feed-item">
        <MagicLink to={showUrl} context={slug} className="item-thumb-container">
          <figure className="item-thumb">
            <ResponsiveImage
              alt={summary.image.alt_text || summary.title}
              src={imgSrc}
              srcset={srcset}
              initialWidth={RESPONSIVE_IMAGE.responsiveImageInitialWidth}
              initialHeight={RESPONSIVE_IMAGE.responsiveImageInitialHeight}
            />
            <figcaption className="short-date">
              <CoastShowDate item={item.record} isShortDate={true} store={store} />

              <span className="item-title">{summary.title}</span>
            </figcaption>
          </figure>
        </MagicLink>

        <section className="item-links">
          <CoastLinkedHost titleOnSameLine={true} item={item.record} />
          <CoastLinkedGuests titleOnSameLine={true} item={item.record} />
        </section>

        {/* Show rich text summary for tonight show */}
        {payload.fields?.summary?.value ? (
          <section className="item-summary" dangerouslySetInnerHTML={{ __html: payload.fields.summary.value }} />
        ) : (
          <section className="item-summary">{summary.description}</section>
        )}
      </div>

      <div className="more-container">
        <MagicLink context={slug} className="more" to={showUrl}>
          More &raquo;
        </MagicLink>
      </div>
    </>
  );
});

export default CoastFeedTonightShowItem;
