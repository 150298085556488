import loadable from "@loadable/component";
import type { FeedResultFragment } from "@ihr-radioedit/inferno-webapi";
import CoastFeedTonightShowItem from "./CoastFeedTonightShowItem.component";

const CoastShowPlaceholder = loadable(() => import("../CoastShowPlaceholder.component"));

interface CoastFeedTonightShowsLoaderProps {
  content: FeedResultFragment[] | null;
  title: string;
}

const CoastFeedTonightShowsLoader = ({ content, title }: CoastFeedTonightShowsLoaderProps) => {
  if (!content?.length) {
    return <CoastShowPlaceholder />;
  }

  // we select might more shows for this block, but we only wanna show the last one
  const show = content[content.length - 1];

  return <CoastFeedTonightShowItem title={title} item={show} />;
};

export default CoastFeedTonightShowsLoader;
